<template>
    <div class="d-block d-lg-none">
    <div class="footer fixed-bottom">
        <nav class="nav nav-fill bottom-nav">
            <router-link to="" @click="goDoctor" class="nav-link bottom-link-navbar footer-image-beforesignup" aria-current="page"
                :class="{ 'tab-activated': isRouteActive('/doctor') }"><font-awesome-icon :icon="['fas', 'user-md']" />
                <p class="bottom-title-navbar">Doctor</p>
            </router-link>
            <router-link to="" @click="goPubic" class="nav-link bottom-link-navbar footer-image-beforesignup" aria-current="page"
                :class="{ 'tab-activated': isRouteActive('/public') }"><font-awesome-icon :icon="['fas', 'user']" />
                <p class="bottom-title-navbar">Public</p>
            </router-link>
            <router-link to="" @click="goSignup" class="nav-link bottom-link-navbar footer-image-beforesignup show"
                aria-current="page" :class="{ 'tab-activated': isRouteActive('/signup') }">
                <p class="mobileButton mb-0 m">Sign in</p>
            </router-link>
            <router-link to="" @click="goContactus" class="nav-link bottom-link-navbar footer-image-beforesignup"
                aria-current="page" :class="{ 'tab-activated': isRouteActive('/vendors') }"><font-awesome-icon
                    :icon="['fas', 'user-tie']" />
                <p class="bottom-title-navbar">Vendor</p>
            </router-link>
            <router-link to="" @click="goAboutus" class="nav-link bottom-link-navbar footer-image-beforesignup" aria-current="page"
                :class="{ 'tab-activated': isRouteActive('/aboutus') }"><font-awesome-icon :icon="['fas', 'info-circle']" />
                <p class="bottom-title-navbar">About us</p>
            </router-link>
         
        </nav>
        <!-- end user -->
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    methods: {
        goDoctor(){ 
            window.scrollTo(0, 0);
      this.$router.push('/doctor');
        },
        goPubic(){
            window.scrollTo(0, 0);
      this.$router.push('/public');
        },
        goSignup(){
            window.scrollTo(0, 0);
      this.$router.push('/signup');
        },
        goAboutus(){
            window.scrollTo(0, 0);
      this.$router.push( '/aboutus'); 
        },
        goContactus(){
            window.scrollTo(0, 0);
      this.$router.push('/vendors');
        },
        currentPath(routePath) {
            this.$router.push(routePath);
        },
        isRouteActive(routePath) {
            return this.$route.path === routePath;
        }
    }
}
</script>
<style>
  .navbarmobileFix{
    margin-bottom: 7rem;
  }
.bottom-title-navbar {
    font-size: 14px;
    margin: 0px;
}

.bottom-link-navbar {
    color: #000;
    font-size: 24px;
}

.mobileButton {
    height: 59px;
    width: 59px;
    border: 2px solid #000;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding-top: 17px;
    font-size: 14px;
    font-weight: bold;
    /* box-shadow: 4px 3px 5px 2px rgba(0, 0, 0, 0.2); */
}

.footer-image-beforesignup {
    margin: 0px !important;
    color: #000 !important;
}

.tab-activated {
    background-color: #00979e;
    color: #fff !important;
    margin: 0px;
}

@media screen and (max-width: 430px) {
    .bottom-link-navbar {
        font-size: 20px;
    }

    .bottom-title-navbar {
        font-size: 9px;
    }

    .mobileButton {
        height: 44px;
        width: 44px;
        font-size: 10px;
        padding-top: 13px;
    }
    .nav-link {
        padding: 0.5rem 0.5rem;
    }
}</style>